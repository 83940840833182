import React from 'react';
import { Checkbox, Col, Form, Input, InputNumber, Row } from 'antd';
import { DatePicker } from 'antd/es';
import dayjs from 'dayjs';

/**
 * Determines if a date should be disabled based on a custom field's configuration.
 *
 * @param {any} current - The current date being evaluated.
 * @param {any} customField - The custom field configuration object.
 * @returns {boolean} - `true` if the date should be disabled, `false` otherwise.
 */
const disabledDate = (current: any, customField: any): boolean => {
  if (customField?.attributes?.allow_past_date_selection === false) {
    return current && current < dayjs().startOf('day');
  } else {
    return false;
  }
};

/**
 * Renders custom fields based on their data type.
 *
 * @param {any[]} customFields - Array of custom fields objects.
 * @param isPreviewMode
 * @returns {React.ReactElement[]} - Array of React elements representing the custom fields.
 */
export const renderCustomFields = (customFields: any[], isPreviewMode?: boolean): React.JSX.Element[] | undefined => {
  return customFields?.map((customField: any) => {
    switch (customField?.dataType) {
      case 'string': {
        const inputAttributes: any = { ...customField?.attributes };
        delete inputAttributes['required'];
        return (
          <Form.Item
            key={customField?.key}
            name={customField?.key}
            label={customField?.label}
            rules={[{ required: customField?.attributes?.required, message: `${customField?.label} is required` }]}
          >
            <Input
              size="large"
              className="rounded-md"
              {...customField?.attributes}
              showCount
              maxLength={200}
              {...inputAttributes}
              disabled={isPreviewMode}
            />
          </Form.Item>
        );
      }
      case 'date':
        return (
          <Form.Item
            name={customField?.key}
            key={customField?.key}
            label={customField?.label}
            rules={[{ required: customField?.attributes?.required, message: `${customField?.label} is required` }]}
          >
            <DatePicker
              size="large"
              key={customField?.key}
              className="rounded-md"
              {...customField?.attributes}
              placeholder={customField?.attributes?.placeholder}
              disabledDate={(current) => disabledDate(current, customField)}
              disabled={isPreviewMode}
            />
          </Form.Item>
        );
      case 'number': {
        const numberAttributes: any = { ...customField?.attributes };
        delete numberAttributes['required'];
        return (
          <Form.Item
            key={customField?.key}
            name={customField?.key}
            label={customField?.label}
            rules={[
              { required: customField?.attributes?.required, message: `${customField?.label} is required` },
              {
                validator: (_, value) => {
                  const minValue = customField?.attributes?.min;
                  const maxValue = customField?.attributes?.max;

                  if (value === undefined || value === null || value === '') {
                    return Promise.resolve();
                  }

                  if (minValue && Number(value) < Number(minValue)) {
                    return Promise.reject(new Error(`Minimum value should be ${minValue}.`));
                  }

                  if (maxValue && Number(value) > Number(maxValue)) {
                    return Promise.reject(new Error(`Maximum value should be ${maxValue}.`));
                  }

                  return Promise.resolve();
                }
              }
            ]}
          >
            <InputNumber<string>
              size="large"
              className="rounded-md"
              type={customField?.dataType}
              {...numberAttributes}
              style={{ width: 270 }}
              controls={true}
              disabled={isPreviewMode}
            />
          </Form.Item>
        );
      }
      case 'boolean':
        return (
          <Form.Item
            key={customField?.key}
            name={customField?.key}
            label={customField?.label}
            valuePropName="checked"
            rules={[
              {
                required: customField?.attributes?.required,
                validator: (_, value) => {
                  if (
                    customField?.attributes?.required &&
                    (value === undefined || value === null || value === '' || value == false)
                  ) {
                    return Promise.reject(`${customField?.label} is required`);
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Checkbox disabled={isPreviewMode}>{customField?.attributes?.checkboxText || ''}</Checkbox>
          </Form.Item>
        );
      default:
        return (
          <Form.Item
            key={customField?.key}
            name={customField?.key}
            label={customField?.label}
            rules={[{ required: customField?.attributes?.required, message: `${customField?.label} is required` }]}
          >
            <Input size="large" className="rounded-md" {...customField?.attributes} />
          </Form.Item>
        );
    }
  });
};
