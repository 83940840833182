import { axios } from 'shared/axiosClient';
import { AxiosErrorHandler } from 'shared/Helpers';
import { AccountData } from 'models/AccountData';

export interface CreateApplicationResponse {
  client_id: string;
  client_secret: string;
  audience: string;
}

export interface ClientApplication extends CreateApplicationResponse {
  name: string;
  description: string | null;
}

/**
 * Asynchronously retrieves the client application data from the server.
 * @returns {Promise<ClientApplication | undefined>} A Promise that resolves to the client application data if the request is successful, or undefined if the request fails.
 * @throws {Error} If the request fails, an Error object with the response message is thrown.
 */
export const getClientApplication = async (): Promise<ClientApplication | undefined> => {
  try {
    const res = await axios.get(`/api/account/applications`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Creates a client application.
 *
 * @returns {Promise<CreateApplicationResponse | undefined>} A Promise that resolves with the response data or undefined if the request fails.
 *
 * @throws {Error} If the request fails, an error object with the error message is thrown.
 */
export const createClientApplication = async (): Promise<ClientApplication | undefined> => {
  try {
    const res = await axios.post(`/api/account/applications`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Deletes the client application associated with the authenticated user's account.
 *
 * @async
 * @returns {Promise<{ id: number; auth0_azp: string | null } | undefined>} - A promise that resolves to an object containing the ID of the deleted client application and the associated authentication party for the application. If the deletion fails, the promise will be rejected with an error message.
 * @throws {Error} - The error thrown if the deletion fails.
 */
export const deleteClientApplication = async (): Promise<{ id: number; auth0_azp: string | null } | undefined> => {
  try {
    const res = await axios.delete(`/api/account/applications`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Rotates the client secret of the client application.
 *
 * @async
 * @returns {Promise<ClientApplication | undefined>} A promise that resolves with the updated client application object, or undefined if an error occurs.
 */
export const rotateClientSecret = async (): Promise<ClientApplication | undefined> => {
  try {
    const res = await axios.post(`/api/account/applications/rotate-secret`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Get Current account
 *
 * @returns account object
 */
export const getCurrentAccount = async (): Promise<AccountData | undefined> => {
  try {
    const res = await axios.get(`/api/account/contract/current`);
    return res.data.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Get Email Domains for account
 * @returns array of email domains
 */
export const getEmailDomainsForAccount = async (): Promise<string[] | undefined> => {
  try {
    const res = await axios.get(`/secure/account/email_domains`);
    return res.data.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};
