import { axios } from 'shared/axiosClient';
import { AxiosErrorHandler } from 'shared/Helpers';
import { CustomField, UpdateCustomFieldRequest } from '../models/CustomField';
import { AxiosResponse } from 'axios';

/**
 * Fetch Custom Fields for the Client
 *
 * @param {number} client_id The client ID
 * @param isEnabled
 * @return {Promise<any>} A promise that resolves with the custom fields data or rejects with an error message
 */
export async function fetchCustomFields(isEnabled: boolean): Promise<any> {
  try {
    let url = `/api/custom_fields`;
    url = isEnabled ? url + `?isEnabled=${isEnabled}` : url;
    const res: AxiosResponse<any, any> = await axios.get(url);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export const insertCustomFields = async (customFields: CustomField[]) => {
  const payload: { customFields: CustomField[] } = { customFields };
  try {
    const res = await axios.post(`/api/custom_fields`, payload);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export const upsertCustomField = async (customFieldId: number, customField: UpdateCustomFieldRequest) => {
  try {
    const res = await axios.patch(`/api/custom_fields/field/${customFieldId}`, customField);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export const deleteCustomField = async (customFieldId: number) => {
  try {
    const res = await axios.delete(`/api/custom_fields/field/${customFieldId}`);
    if (res.data?.status === 'success') {
      return res.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export const fetchPublishStatus = async () => {
  try {
    const res = await axios.get(`/api/custom_fields/published_status`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};
export const publishCustomField = async (isPublished: boolean) => {
  try {
    const res = await axios.patch(`/api/custom_fields/published_status/toggle`, {
      isPublished
    });
    if (res.data?.status === 'success') {
      return res.data;
    }
  } catch (e) {
    AxiosErrorHandler(e);
  }
};
export const updateDisplayOrder = async (payload: { id: number | undefined; orderBy: number }[]) => {
  try {
    const res = await axios.patch(`/api/custom_fields/display_order`, {
      orderByData: payload
    });
    if (res.data?.status === 'success') {
      return res.data;
    }
  } catch (e) {
    AxiosErrorHandler(e);
  }
};
