import { Col, Divider, Image, Modal, ModalProps, Row, Skeleton, Space, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { fetchDeal, removeContractFile } from 'api/contract';
import './DealModal.scss';
import { Deal } from 'models/Deal';
import { File } from 'models/Files';
import fallbackLogo from 'assets/fallbackLogo.png';
import { DealSupplierModalMenu, MenuKeys } from 'components/atoms/DealSupplierModalMenu';
import DealModalStats from './DealModalStats';
import { Collapse } from 'react-collapse';
import { Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { profileSwitchState, selectedSupplierDynamicsVendorState } from 'recoil/atoms';
import { ANONYMOUS_CLIENT_NAME } from 'shared/constants';
import { FilesTable } from 'components/molecules/FilesTable';
import { cloneDeep } from 'lodash-es';
import DealModalSummary from './DealModalSummary';
import ModalSupplierDynamics from 'components/atoms/ModalSupplierDynamics';
import { CloseOutlined } from '@ant-design/icons';
import DealNotes from './DealNotes';
import CalendarDealSummary from 'pages/deal/client/calendar-deals/CalendarDealSummary';

interface Props extends ModalProps {
  id?: number;
  onCancel?: () => void;
  onDeleteDeal?: (dealId: number) => void;
}

/**
 * React functional component representing the deal modal.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {React.ReactElement} The deal modal component.
 */
export const DealModal: React.FC<Props> = ({ id, onCancel, onDeleteDeal, ...rest }: Props): React.ReactElement => {
  // LOCAL STATE
  const [deal, setDeal] = useState<undefined | Deal>(undefined);
  const [loading, setLoading] = useState(false);
  const [selectedMenuKeys, setSelectedMenuKeys] = useState<Array<MenuKeys>>([MenuKeys.Summary]);

  // RECOIL STATE
  const setSelectedSupplier = useSetRecoilState(selectedSupplierDynamicsVendorState);
  const profileSwitch = useRecoilValue(profileSwitchState);

  /**
   * Fetches deal information from the server.
   *
   * @async
   * @function fetchDealInfo
   * @returns {Promise<void>} A promise that resolves when the deal information is fetched.
   * @throws Will throw an error if fetching the deal information fails.
   * @description This function fetches the deal information from the server. It sets the loading state to true and the selected menu keys to [MenuKeys.Summary] before making the API call. If the provided id is falsy, the function will return early. Once the deal information is fetched, it sets the deal state and updates the selected supplier with the deal's vendor information. Finally, it sets the loading state back to false.
   */
  const fetchDealInfo = useCallback(async (): Promise<void> => {
    if (!id) return;
    setLoading(true);
    setSelectedMenuKeys([MenuKeys.Summary]);
    try {
      const deal: undefined | Deal = await fetchDeal(id);
      setDeal(deal);
      if (deal) {
        setSelectedSupplier({
          id: deal.vendor.id,
          name: deal.vendor.name,
          supplier_category: []
        });
      }
    } finally {
      setLoading(false);
    }
  }, [id, setSelectedSupplier]);

  useEffect((): void => {
    (async (): Promise<void> => {
      await fetchDealInfo();
    })();
  }, [fetchDealInfo]);

  /**
   * React element representing a vertical divider.
   *
   * @type {React.ReactElement}
   */
  const VerticalDivider: React.ReactElement = (
    <Col span={1} style={{ textAlign: 'center' }}>
      <Divider className={'vertical-divider'} type={'vertical'} />{' '}
    </Col>
  );

  /**
   * Handles the selection of a menu item.
   *
   * @param {any} selection - The selected menu item.
   * @returns {void}
   */
  const handleOnMenuSelect = (selection: any): void => {
    const key: MenuKeys = selection.key;
    setSelectedMenuKeys([key]);
  };

  /**
   * Handles the deletion of a file.
   *
   * @param {File} removedFile - The file to be removed.
   * @returns {Promise<void>} Returns a promise that resolves with no value.
   */
  const deleteFileHandler = async (removedFile: File): Promise<void> => {
    if (!deal) return;
    const res = await removeContractFile(deal.id, removedFile.key);
    if (res) {
      const _deal = cloneDeep(deal);
      const files = _deal.file.filter((f) => f.id !== removedFile.id);
      Object.assign(_deal, { file: files });
      setDeal(_deal);
    }
  };

  /**
   * Handles the event when files are changed.
   *
   * @async
   * @return {Promise<void>}
   */
  const onFilesChangeHandler = async (): Promise<void> => {
    await fetchDealInfo();
    setSelectedMenuKeys([MenuKeys.Files]);
  };

  /**
   * Function to refetch deal information.
   *
   * @async
   * @returns {Promise<void>} Promise that resolves with void.
   */
  const refetchDeal = async (): Promise<void> => {
    await fetchDealInfo();
  };

  return (
    <Modal
      className="deal-modal"
      title={
        <Skeleton loading={loading} paragraph={{ rows: 1 }} avatar={{ shape: 'square' }} active>
          <Row>
            <Col span={2} className={'flex flex-col flex-grow place-content-center text-center'}>
              <Image
                style={{ maxHeight: 48 }}
                src={deal?.vendor.logo_url || fallbackLogo}
                fallback={fallbackLogo}
                preview={false}
              />
            </Col>

            {VerticalDivider}
            <Col span={21} className={'flex flex-col flex-grow place-content-center'}>
              <Space
                split={<Divider type="vertical" style={{ borderLeft: '1px solid rgba(0,0,0,.26)', height: 18 }} />}
              >
                <div>{!profileSwitch?.anonymous ? deal?.client.name : ANONYMOUS_CLIENT_NAME}</div>
                <div>
                  {deal?.salesforce_deal_name ? (
                    <Tooltip title="Click to view details">
                      <Link to={`/deal_details/${deal?.id}`}>{deal?.salesforce_deal_name}</Link>
                    </Tooltip>
                  ) : (
                    deal?.name
                  )}
                </div>
                <div>{deal?.salesforce_deal_id && <>{deal?.subject}</>}</div>
              </Space>
              {/*{!profileSwitch?.anonymous ? deal?.client.name : ANONYMOUS_CLIENT_NAME} &#65372;{' '}*/}
              {/*{deal?.salesforce_deal_name || deal?.name} {deal?.salesforce_deal_id && <>&#65372; {deal?.subject}</>}*/}
            </Col>
          </Row>
        </Skeleton>
      }
      closeIcon={<CloseOutlined onClick={onCancel} />}
      width={1200}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      forceRender={true}
      footer={null}
      {...rest}
    >
      <Skeleton loading={loading} active>
        <DealSupplierModalMenu
          selectedKeys={selectedMenuKeys}
          supplierDynamics={deal?.vendor._count && deal?.vendor._count.supplier_deal_dynamic > 0}
          onSelect={handleOnMenuSelect}
        />
        <br />
        <Collapse isOpened={selectedMenuKeys.includes(MenuKeys.Summary)}>
          {deal?.salesforce_deal_id && (
            <div>
              <DealModalStats deal={deal} />
              <Divider />
            </div>
          )}
          <div className="pt-3">
            {deal?.sfDeal ? (
              <DealModalSummary
                deal={deal}
                closeModal={onCancel}
                refetchDeal={refetchDeal}
                onDeleteDeal={onDeleteDeal}
              />
            ) : (
              <CalendarDealSummary deal={deal} refetchDeal={refetchDeal} />
            )}
          </div>
        </Collapse>
        <Collapse isOpened={selectedMenuKeys.includes(MenuKeys['Supplier Dynamics'])}>
          <ModalSupplierDynamics />
        </Collapse>
        <Collapse isOpened={selectedMenuKeys.includes(MenuKeys.Files)}>
          <FilesTable id={id} dataSource={deal?.file} onDelete={deleteFileHandler} onChange={onFilesChangeHandler} />
        </Collapse>
        <Collapse isOpened={selectedMenuKeys.includes(MenuKeys.Notes)}>
          <DealNotes contract_id={deal?.id} />
        </Collapse>
      </Skeleton>
    </Modal>
  );
};
